import { GameScenario, GAME_STEP_TYPE } from "./types";

// page
// 1 -> ułóż w kolejności
// 2->układanka 4x4
// 3->Memory
// 4-8->quiz
// 9-16 -> quiz bravo
// 17-21 ->znajdź na obrazie
// 22-> wpisz hasło
//23 ->wybierz z listy
// 24-31 -> opisy wstępu

export const SCENARIO: GameScenario = {
    startStep: '1.0',
    steps: [
        {
            id: '1.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Zielona Willa, ul.Zygmunta Krasińskiego 34'],
            position:{
                lat: 52.14110175131355,
                lng: 20.81654576601415
            },
            nextStep: '1.1'
        },
        {
            id: '1.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-24', //plot
            nextStep: '1.2'
        },
        {
            id: '1.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-23',//wybierz z listy
            nextStep: '2.0'
        },
        {
            id: '2.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Kowal, ul. Cypriana Kamila Norwida 5'],
            position:{
                lat: 52.138745061784206,
                lng: 20.81825921913155
            },
            nextStep: '2.1'
        },
        {
            id: '2.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-25',//ekran plot
            nextStep: '2.2'
        },
        {
            id: '2.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-1', //ułóż w kolejności grafiki
            nextStep: '3.0'
        },
        {
            id: '3.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Plac Paderewskiego 1'],
            position:{
                lat: 52.14318631120229,
                lng: 20.81814831799414
            },
            nextStep: '3.1'
        },
        {
            id: '3.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-26',//ekran plot
            nextStep: '3.2'
        }, {
            id: '3.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-4', //quiz
            nextStep: '3.3'
        },
        {
            id: '3.3',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-5',//quiz
            nextStep: '3.4'
        },
        {
            id: '3.4',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-6',//quiz
            nextStep: '3.5'
        },
        {
            id: '3.5',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-7',//quiz
            nextStep: '3.6'
        },
        {
            id: '3.6',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-8',//quiz
            nextStep: '4.0'
        },
        {
            id: '4.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Szkoła Podstawowa im. M. Dąbrowskiej, wejście do ul. Henryka Kotońskiego 5'],
            position:{
                lat: 52.14577046210419,
                lng: 20.815962478648924
            },
            nextStep: '4.1'
        },
        {
            id: '4.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-27',//ekran plot
            nextStep: '4.2'
        },
        {
            id: '4.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-22',//wpisz hasło
            nextStep: '5.0'
        },
        {
            id: '5.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['WKD Komorów, aleja Marii Dąbrowskiej 1'],
            position:{
                lat: 52.1479359613537,
                lng: 20.811501059919713
            },
            nextStep: '5.1'
        },
        {
            id: '5.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-28',//ekran plot
            nextStep: '5.2'
        },
        {
            id: '5.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-3', //memory
            nextStep: '6.0'
        },
        {
            id: '6.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Stara Cukiernia, Bankowa 1'],
            position:{
                lat: 52.146022737169886,
                lng: 20.811078089113543
            },
            nextStep: '6.1'
        },
        {
            id: '6.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-29',//ekran plot
            nextStep: '6.2'
        },
        {
            id: '6.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-2', //układanka 4x4
            nextStep: '7.0'
        },
        {
            id: '7.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Kościół pw. Narodzenia NMP, ul.  3 Maja 9'],
            position:{
                lat: 52.144851843789134,
                lng: 20.813968273877816
            },
            nextStep: '7.1'
        },
        {
            id: '7.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-30',//ekran plot
            nextStep: '7.2'
        },
        {
            id: '7.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-17',//znajdź na obrazie
            nextStep: '7.3'
        },{
            id: '7.3',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-18',//znajdź na obrazie
            nextStep: '7.4'
        },{
            id: '7.4',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-19',//znajdź na obrazie
            nextStep: '7.5'
        },{
            id: '7.5',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-20',//znajdź na obrazie
            nextStep: '7.6'
        },{
            id: '7.6',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-21',//znajdź na obrazie
            nextStep: '8.0'
        },
        {
            id: '8.0',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Gminna Biblioteka Publiczna im. Marii Dąbrowskiej, ul. Józefa Kraszewskiego 5'],
            position:{
                lat: 52.14368227849169,
                lng: 20.81573804026853
            },
            nextStep: '8.1'
        },
        {
            id: '8.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-31',//ekran plot
            nextStep: '10.1'
        },
        {
            id: '10.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-9',//bravo
            nextStep: '10.2'
        },
        {
            id: '10.2',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-10',//bravo
            nextStep: '10.3'
        },
        {
            id: '10.3',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-11',//bravo
            nextStep: '10.4'
        },{
            id: '10.4',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-12',//bravo
            nextStep: '10.5'
        },{
            id: '10.5',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-13',//bravo
            nextStep: '10.6'
        },{
            id: '10.6',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-14',//bravo
            nextStep: '10.7'
        },{
            id: '10.7',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-15',//bravo
            nextStep: '10.8'
        },{
            id: '10.8',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'page-16',//bravo result
            nextStep: '11'
        },{
            id: '11',
            type:GAME_STEP_TYPE.GEO_STEP,
            name:['Zielona Willa, ul.Zygmunta Krasińskiego 34'],
            position:{
                lat: 52.14110175131355,
                lng: 20.81654576601415
            },
            nextStep: '11.1'
        },
        {
            id: '11.1',
            type:GAME_STEP_TYPE.PAGE,
            pageId: 'summary-page',
            nextStep: null
        },
    ]
}